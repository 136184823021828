const Tables = {
  init: () => {
    if (
      document.getElementById('elemental-area') &&
      document.getElementById('elemental-area').getElementsByTagName('table')
    ) {
      const tables = document.getElementById('elemental-area').getElementsByTagName('table');

      let w = document.documentElement.clientWidth || document.body.clientWidth;

      for (const table of tables) {
        const wrapper = document.createElement('div');
        wrapper.classList.add('table-wrapper');
        wrapper.setAttribute('id', 'table-wrapper');

        const scrollOverflowLeft = document.createElement('div');
        scrollOverflowLeft.classList.add(
          'scroll-overflow-left',
          'hidden',
          'md:hidden',
          'lg:hidden',
          'absolute',
          'h-full',
          'w-12',
          'left-0',
          'top-0'
        );

        const scrollOverflowRight = document.createElement('div');
        scrollOverflowRight.classList.add(
          'scroll-overflow-right',
          'md:hidden',
          'lg:hidden',
          'absolute',
          'h-full',
          'w-12',
          'top-0'
        );
        table.parentNode.insertBefore(wrapper, table);
        wrapper.appendChild(table);
        table.appendChild(scrollOverflowRight);
        table.appendChild(scrollOverflowLeft);

        let offsetRight = `${(table.clientWidth - w + 32).toString()}px`;
        scrollOverflowRight.style.right = offsetRight;

        window.addEventListener('resize', () => {
          w = document.documentElement.clientWidth || document.body.clientWidth;
          offsetRight = `${(table.clientWidth - w + 32).toString()}px`;
          scrollOverflowRight.style.right = offsetRight;

          const scrollAmount = document.getElementById('table-wrapper').scrollLeft;

          const { offsetWidth } = document.getElementById('table-wrapper');

          const { scrollWidth } = document.getElementById('table-wrapper');

          const tableWidth = table.clientWidth;

          if (scrollAmount > 1) {
            scrollOverflowLeft.classList.remove('hidden');
            scrollOverflowLeft.style.left = `${scrollAmount.toString()}px`;
          }

          if (scrollAmount === 0) {
            scrollOverflowLeft.classList.add('hidden');
          }

          if (scrollAmount !== tableWidth / 2 - 6) {
            if (scrollOverflowRight.classList.contains('hidden')) {
              scrollOverflowRight.classList.remove('hidden');
            }
          }

          if (scrollAmount + offsetWidth === scrollWidth) {
            scrollOverflowRight.classList.add('hidden');
          }

          const newOffsetRight = `${
            (table.clientWidth - w + 32).toString() - scrollAmount.toString()
          }px`;
          scrollOverflowRight.style.right = newOffsetRight;
        });

        document.getElementById('table-wrapper').addEventListener('scroll', () => {
          const scrollAmount = document.getElementById('table-wrapper').scrollLeft;

          const { offsetWidth } = document.getElementById('table-wrapper');

          const { scrollWidth } = document.getElementById('table-wrapper');

          const tableWidth = table.clientWidth;

          if (scrollAmount > 1) {
            scrollOverflowLeft.classList.remove('hidden');
            scrollOverflowLeft.style.left = `${scrollAmount.toString()}px`;
          }

          if (scrollAmount === 0) {
            scrollOverflowLeft.classList.add('hidden');
          }

          if (scrollAmount !== tableWidth / 2 - 6) {
            if (scrollOverflowRight.classList.contains('hidden')) {
              scrollOverflowRight.classList.remove('hidden');
            }
          }

          if (scrollAmount + offsetWidth === scrollWidth) {
            scrollOverflowRight.classList.add('hidden');
          }

          const newOffsetRight = `${
            (table.clientWidth - w + 32).toString() - scrollAmount.toString()
          }px`;
          scrollOverflowRight.style.right = newOffsetRight;
        });
      }
    }
  },
};

export default Tables;
